import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ReactGA from 'react-ga';
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Index from "./Components/Index";
import Aboutjci from "./About/Aboutjci";
import AboutJciIndia from "./About/AboutJciIndia";
import AboutJcinagpurFortune from "./About/AboutJcinagpurFortune";
import PdfDownloader from "./Components/Download";
import NPCorner from "./About/NPCorner";
import Events from "./Events/Events";
import OurTeam from "./Team/OurTeam";
import OurJciMembers from "./Team/OurJciMembers";
import Gallery from "./Components/Gallery";
import NotFound from "./NotFound";
const trackingId = "G-XQGMYG40J6";
ReactGA.initialize(trackingId);



// import Try from "./Components/Try";

function App() {

    ReactGA.pageview(window.location.pathname + window.location.search);
  
  return (
    <>
      
      <Router>
      {/* <div className="preloader"></div> */}
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/aboutjci" element={<Aboutjci />} />
          <Route path="/aboutjciindia" element={<AboutJciIndia />} />
          <Route path="/aboutjcinagpurfortune" element={<AboutJcinagpurFortune />} />
          <Route path="/npcorner" element={<NPCorner/>} />
          <Route path="/downloads" element={<PdfDownloader/>} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/try" element={<Try />} /> */}
          <Route path="/ourteam" element={<OurTeam />} />
          <Route path="/ourjcimembers" element={<OurJciMembers />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* Fallback route for 404 */}
        <Route component={NotFound} />
       


        </Routes>
      
        <Footer />
      </Router>


      
    </>
  );
}

export default App;
