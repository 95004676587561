import React from "react";
import './Header.css';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const schemaMarkup = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "JCI Nagpur Fortune",
  "url": "https://www.jcinagpurfortune.in/",
  "logo": "https://www.jcinagpurfortune.in/images/images/logo4.png",
  "image": "https://www.jcinagpurfortune.in/images/logo.png",
  "description": "Join JCI Nagpur Fortune to empower youth and create positive change in your community",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "1475"
  },
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Lower Ground Fortune Mall, behind Maharashtra bank, Sitabuldi, Nagpur",
    "addressLocality": "Nagpur",
    "addressRegion": "Maharashtra",
    "postalCode": "440012",
    "addressCountry": "India"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61566611071468",
    "https://www.instagram.com/jcinagpurfortune/",
    "https://x.com/jcinagpufortune",
    "https://www.linkedin.com/in/jci-nagpur-fortune-601620330/"
  ],
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+919975288300",
    "contactType": "customer support"
  }
};

function Header() {
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-XQGMYG40J6', {
      page_path: location.pathname,
    });
  }, [location]);

  return (
<>
<Helmet>
        <title>JCI Nagpur Fortune - Youth Empowerment Organization</title>
        <meta name="description" content="Join JCI Nagpur Fortune to empower youth and create positive change in your community." />
        <meta name="keywords" content="JCI India, youth empowerment, leadership, community service,JCI Nagpur Fortune,Nagpur,developmet,growth,Non-profit Organization,Maharashtra,India,Best Organization in Nagpur,Fortune,sitaburdi" />
        <link rel="canonical" href="https://www.jcinagpurfortune.in//" />
      </Helmet>

    <header className="main-header">
      {/* Header Upper */}
      <div className="header-upper">
        <div className="auto-container">
          <div className="clearfix desktop-only">
            <div className="pull-right upper-right">
              <div className="info-outer clearfix">
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="flaticon-home-1"></span>
                  </div>
                  <ul>
                    <li>
                      <span>Lower Ground Fortune Mall</span>
                      <br />
                      behind Maharashtra bank, Sitabuldi
                    </li>
                  </ul>
                </div>
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="flaticon-envelope"></span>
                  </div>
                  <ul>
                    <li>
                      <span>Email ID</span>
                      <br />
                      jci.prashant@gmail.com
                    </li>
                  </ul>
                </div>
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="flaticon-telephone"></span>
                  </div>
                  <ul>
                    <li>
                      <span>Contact No</span>
                      <br />
                      9975288300
                    </li>
                  </ul>
                </div>
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <img src="/images/icons/user-removebg-preview.png" alt="jcinagpurfortune" width={"28px"}></img>
                  </div>
                  <li>
                    <button
                      type="button"
                      className="btn btn-primary flaticon-login"
                      onClick={() => window.location.href = 'https://members.jciindia.in/#/login'}
                    >
                      Members Login
                    </button>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-lower">
        <div className="auto-container clearfix">
          <div className="logo pull-left">
            <a href="/" className="img-responsive"><img src="images/logo4.png" alt="jcinagpurfortune" title="" /></a>
          </div>
          <div className="right-col pull-right">
            <div className="nav-outer clearfix">
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasMenu"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                  <ul className="navigation clearfix">
                    <li className="current dropdown">
                      <a href="/" style={{ textDecoration: "none" }}>Home</a>
                    </li>
                    <li className="dropdown">
                      <a href="#" style={{ textDecoration: "none" }}>About</a>
                      <ul>
                        <li><a href="/aboutjci">About JCI</a></li>
                        <li><a href="/aboutjciindia">About JCI India</a></li>
                        <li><a href="/NPCorner">NP Corner</a></li>
                        <li><a href="#">JCI Global</a></li>
                      </ul>
                    </li>

                    {/* <li className="dropdown">
                      <a href="/ourteam" style={{ textDecoration: "none" }}>Team</a>
                      <ul>
                        <li><a href="/ourteam" style={{ textDecoration: "none" }}>Our Team</a></li>
                        <li><a href="/ourjcimembers" style={{ textDecoration: "none" }}>Our JCI Members</a></li>
                      </ul>
                    </li> */}




<li>
                      <a href="/ourteam" style={{ textDecoration: "none" }}>Team</a>
                      
                    </li>





                    <li className="dropdown">
                      <a href="#" style={{ textDecoration: "none" }}>Events</a>
                      <ul>
                        <li><a href="#" style={{ textDecoration: "none" }}>National Events</a></li>
                        <li><a href="#" style={{ textDecoration: "none" }}>Zone Event</a></li>
                        <li><a href="#"style={{ textDecoration: "none" }}>LO</a></li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a href="#" style={{ textDecoration: "none" }}>Programs</a>
                      <ul>
                        <li><a href="#" style={{ textDecoration: "none" }}>National Programs</a></li>
                        <li><a href="#"style={{ textDecoration: "none" }}>Community Development</a></li>
                      </ul>
                    </li>
                    <li><a href="/gallery" style={{ textDecoration: "none" }}>Gallery</a></li>
                    <li><a href="/Downloads" style={{ textDecoration: "none" }}>Downloads</a></li>
                    <li><a href="/contact" style={{ textDecoration: "none" }}>Contact us</a></li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Off-Canvas Menu */}
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
        <div className="offcanvas-header">
          <h6 className="offcanvas-title" id="offcanvasMenuLabel">Welcome to JCI Nagpur Fortune</h6>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>

        </div>
        <div className="logo pull-left">
          <a href="/" className="img-responsive">
            <img src="images/logo.png" alt="jcinagpurfortune" title="" />
          </a>
        </div>
        <div className="offcanvas-body">
          <ul className="navigation">
            <li className="current dropdown"><a href="/" style={{ textDecoration: "none", color: "black" }}>Home</a></li>
            <li className="dropdown">
              <a href="/aboutjci" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "black" }}>
                About
              </a>
              <ul className="dropdown-menu" style={{ textDecoration: "none", color: "black" }}>
                <li><a className="dropdown-item"style={{ textDecoration: "none" }} href="/aboutjci">About JCI</a></li>
                <li><a className="dropdown-item"style={{ textDecoration: "none" }} href="/aboutjciindia">About JCI India</a></li>
                <li><a className="dropdown-item"style={{ textDecoration: "none" }} href="/NPCorner">NP Corner</a></li>
                <li><a className="dropdown-item"style={{ textDecoration: "none" }} href="#">JCI Global</a></li>
              </ul>
            </li>
            <li><a href="/ourteam" style={{ textDecoration: "none", color: "black" }}>Team</a></li>
            <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "black" }}>Events</a>
              <ul className="dropdown-menu" style={{ textDecoration: "none", color: "black" }}>
                <li><a className="dropdown-item" style={{ textDecoration: "none" }} href="#">National Events</a></li>
                <li><a className="dropdown-item" style={{ textDecoration: "none" }}href="#">Zone Event</a></li>
                <li><a className="dropdown-item" style={{ textDecoration: "none" }}href="#">LO</a></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "black" }}>Programs</a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" style={{ textDecoration: "none" }} href="#">National Programs</a></li>
                <li><a className="dropdown-item" style={{ textDecoration: "none" }} href="#">Community Development</a></li>
              </ul>
            </li>
            <li><a href="/gallery" style={{ textDecoration: "none", color: "black" }}>Gallery</a></li>
            <li><a href="/Downloads" style={{ textDecoration: "none", color: "black" }}>Downloads</a></li>
            <li><a href="/contact" style={{ textDecoration: "none", color: "black" }}>Contact us</a></li>
            <li>
              <button
                type="button"
                className="btn btn-primary flaticon-login"
                onClick={() => window.location.href = 'https://members.jciindia.in/#/login'}
              >
                Members Login <img src="/images/icons/user-removebg-preview.png" alt="jcinagpurfortune" width={"20px"}></img>
              </button>
            </li>
          </ul>

          {/* Header Upper */}
          <div className="header-upper">
            <div className="auto-container">
              <div className="clearfix">
                <div className="pull-right upper-right">
                  <div className="info-outer clearfix">
                    <div className="upper-column info-box">
                      <div className="icon-box">
                        <span className="flaticon-home-1"></span>
                      </div>
                      <ul>
                        <li>
                          <span>Lower Ground Fortune Mall</span>
                          <br />
                          behind Maharashtra bank, Sitabuldi
                        </li>
                      </ul>
                    </div>
                    <div className="upper-column info-box">
                      <div className="icon-box">
                        <span className="flaticon-envelope"></span>
                      </div>
                      <ul>
                        <li>
                          <span>Email ID</span>
                          <br />
                          jci.prashant@gmail.com
                        </li>
                      </ul>
                    </div>
                    <div className="upper-column info-box">
                      <div className="icon-box">
                        <span className="flaticon-telephone"></span>
                      </div>
                      <ul>
                        <li>
                          <span>Contact No</span>
                          <br />
                          9975288300
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Header */}
      <div className="sticky-header">
        <div className="auto-container clearfix">
          <div className="logo pull-left">
            <a href="/" className="img-responsive"><img src="images/logo.png" alt="jcinagpurfortune" title="" /></a>
          </div>
          <div className="right-col pull-right">
            <nav className="main-menu navbar-expand-md">
              <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu" aria-label="Toggle navigation">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                <ul className="navigation clearfix">
                  <li className="current dropdown">
                    <a href="/" style={{ textDecoration: "none" }}>Home</a>
                  </li>
                  <li className="dropdown">
                    <a href="#" style={{ textDecoration: "none" }}>About</a>
                    <ul>
                      <li><a href="/aboutjci" style={{ textDecoration: "none" }}>About JCI</a></li>
                      <li><a href="/aboutjciindia" style={{ textDecoration: "none" }}>About JCI India</a></li>
                      <li><a href="/NPCorner" style={{ textDecoration: "none" }}>NP Corner</a></li>
                      <li><a href="#" style={{ textDecoration: "none" }}>JCI Global</a></li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="/ourteam" style={{ textDecoration: "none" }}>Team</a>
                    {/* <ul>
                      <li><a href="/ourteam">Our Team</a></li>
                      <li><a href="/ourjcimembers">Our JCI Members</a></li>
                    </ul> */}
                  </li>

                  <li className="dropdown">
                    <a href="#" style={{ textDecoration: "none" }}>Events</a>
                    <ul>
                      <li><a href="/events">National Events</a></li>
                      <li><a href="/events">Zone Event</a></li>
                      <li><a href="/events">LO</a></li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="#" style={{ textDecoration: "none" }}>Programs</a>
                    <ul>
                      <li><a href="#">National Programs</a></li>
                      <li><a href="#">Community Development</a></li>
                    </ul>
                  </li>
                  <li><a href="/gallery" style={{ textDecoration: "none" }}>Gallery</a></li>
                  {/* <li><a href="#" style={{ textDecoration: "none" }}>Gallery</a></li> */}
                  <li><a href="/contact" style={{ textDecoration: "none" }}>Contact us</a></li>

                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Scroll to top */}
      <div className="scroll-to-top scroll-to-target" data-target="html">
        <span className="fa fa-arrow-up"></span>
      </div>
    </header>
    </>
  );
}

export default Header;
